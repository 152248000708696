<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            :items="MY_BOOKINGS"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >
          <template #cell(actions)="data">
            <b-button
                :to="{
                name: 'booking-details',
                params: { bookingid: data.item.id },
              }"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                size="sm"
            >
              {{ $t("Details") }}
            </b-button>
            <b-button
                :target="'_blank'"
                :to="{
                name: 'event-room-show',
                params: {
                  booking_id:data.item.id,
                  event_id: data.item.event_id,
                },
              }"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
                size="sm"
            >
              {{ $t("Event Room") }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2" v-if="items.length >= perPage">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              {{ $t("Showing") }}
              <span v-if="items.length === 0"> {{ items.length }}</span>
              <span v-else-if="items.length > 0">
                {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span
              >
              {{ $t("to") }}
              <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
              <span v-else-if="perPage * currentPage > items.length">
                {{ items.length }}</span
              >
              <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
              {{ $t("of") }} {{ items.length }} {{ $t("entries") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: true,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      tableColumns: [
        {
          key: "start_date",
          sortable: true,
          label: this.$i18n.t("Event Date"),
          formatter: (value) => {
            return moment(String(value)).format("DD.MM.YYYY");
          },
        },
        {
          key: "event.name",
          sortable: true,
          label: "Name",
        },
        {
          key: "credits",
          sortable: true,
          label: "# Credits",
        },
        {
          key: "event.host",
          sortable: true,
          label: this.$i18n.t("Host"),
        },
        {
          key: "actions",
          label: this.$i18n.t("Actions"),
        },
      ],
      items: [],
    };
  },

  mounted() {
    this.GET_MY_BOOKINGS('client/bookings/pastBookings').then(() => {
      this.items = this.MY_BOOKINGS;
      this.loaded = false;
    });
  },

  computed: {
    ...mapGetters(["MY_BOOKINGS"]),
  },

  methods: {
    ...mapActions(["GET_MY_BOOKINGS"]),

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email;
      if (!item.firstname) return item.name;
      return item.firstname + " " + item.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
